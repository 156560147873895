import './Home.css';
import React from "react";
import StructuredData from "react-google-structured-data";

//components
import Videosection from './components/Videosection/Videosection';
import Footer from './components/Footer/Footer';

//images
import wireframe from './images/home/wireframe.jpg';
import graph2d from './images/home/ptaki.jpg';
import model3d from './images/home/mechanics.jpg';

//video
import anim from './video/bw_baner.mp4'

const Home = () => {

        return (
            <div>
                <StructuredData
                    type='Organization'
                    data={{
                    url: 'https://rnd.team',
                    logo: 'https://plotoptix.rnd.team/images/logo_rnd_design_studio_white_1000x500.png',
                    }}
                />
                <div className="beginning"> 
                    <div>
                        <Videosection id = "home" />
                
                        <div className="infoframe">        
                            <div className="infotext">      
                                <p>
                                    <span style={{fontFamily:"Inter", fontSize:"28px"}}><b>Sharpness is a bourgeois concept</b></span><br/>
                                    <span style={{fontFamily:"Inter", fontSize:"18px"}}><i>Henri Cartier-Bresson</i></span>
                                </p>
                            </div>        
                        </div>

                        <div className="infoframe">        
                            <p>
                                <span className='title'>Services</span><br/>
                            </p>       
                        </div>
                    </div>
                </div>

                    {/*k a f e l k i*/}

                <div className="row-wrapper25home">
                    {/*pierwszy kafelek*/}
                    <div className="kafelekhome">
                        <img src={wireframe} alt="wireframe"></img>
                        <div className='box2home'> 
                        
                            <h2 style={{color: 'white'}}>
                                ML/AI for 2D/3D imaging
                            </h2>

                            <ul style={{color: `white`}}>
                                <li>Analysis</li>
                                <li>Reconstruction</li>
                                <li>Generation</li>
                            </ul>

                        </div>
                    </div>
                    {/*koniec pierwszego kafelka*/}   

                    {/*drugi kafelek*/}
                    <div className="kafelekhome">
                        <img src={model3d} alt="engine"></img>
                        <div className='box2home'>
                            <h2 style={{color: 'white'}}>3D Design</h2>

                            <ul style={{color: `white`}}>
                                <li>Modeling</li>
                                <li>Texturing</li>
                                <li>Rendering</li>
                            </ul>
                        </div>

                    </div>
                    {/*koniec drugiego kafelka*/}

                    {/*trzeci kafelek*/}
                    <div className="kafelekhome">
                        <video src={anim} autoPlay loop muted/>

                        <div className='box2home'>

                            <h2 style={{color: 'white'}}>3D animation</h2>

                            <ul style={{color: `white`}}>
                                <li>Product demo videos</li>
                                <li>Advertising videos</li>
                            </ul>    
                        
                        </div>

                    </div>
                    {/*koniec trzeciego kafelka*/}

                    {/*czwarty kafelek*/}
                    <div className="kafelekhome">
                        <img src={graph2d} alt="graph2d"></img>

                        <div className='box2home'>

                            <h2 style={{color: 'white'}}>2D Graphic Design</h2>

                            <ul style={{color: `white`}}>
                                <li>Visual identification</li>
                                <li>Websites</li>
                                <li>Publications</li>
                            </ul>  

                        </div>

                    </div>
                    {/*koniec czwartego kafelka*/}
             
             </div>
            
             <Footer />

            </div>
        )
}

export default Home;